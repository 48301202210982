<template>
    <div class="form" style="margin-bottom:35px">
        <el-form :model="searchForm">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="代理商名称：">
                        <el-input v-model="searchForm.company" />
                    </el-form-item>
                </el-col>
                <el-col :span="3">
                    <el-form-item>
                        <el-button type="primary" @click="search">
                            <el-icon style="color:#fff">
                                <Search />
                            </el-icon>
                            搜索
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table">
        <div class="table-head">
            <span class="table-title">代理商列表</span>
            <span class="table-btn">
                <el-button @click="addAgent" type="primary">
                    <el-icon style="color:#fff">
                        <Plus />
                    </el-icon>
                    <span style="margin-left:15px">添加代理商</span>
                </el-button>
            </span>
        </div>
        <div class="table-main">
            <c-table ref="tableRef" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="true">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column label="代理商名称" prop="company" />
                <el-table-column label="代理商编号" prop="no" />
                <el-table-column label="联系人" prop="name" />
                <el-table-column label="联系电话" prop="phone" />
                <el-table-column label="层级" prop="depth" />
                <el-table-column label="创建时间" prop="created_at">
                    <template #default="scope">
                        {{ timeFormat(scope.row.created_at) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <template v-if="scope.row.depth < 3">
                            <el-link type="primary" @click="configureRate(scope.row)">配置费率</el-link>
                            <el-link class="ml15" type="primary" @click="openDevice(scope.row)">分配设备</el-link>
                            <el-dropdown class="ml15 link" :hide-on-click="false">
                                ...
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click="editAgent(scope.row)">编辑</el-dropdown-item>
                                        <el-dropdown-item @click="deleteAgent(scope.row)">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </template>
                </el-table-column>
            </c-table>
        </div>
    </div>
    <el-dialog v-model="isShowAddAgent" width="600px" @close="closeDialog">
        <template #header>
            添加代理商
        </template>
        <el-form ref="agentFormRef" :model="agentForm" :rules="rules" label-width="110px" :scroll-to-error="true">
            <el-row>
                <el-col :span="20" :offset="2">
                    <el-form-item label="代理商名称：" prop="company">
                        <el-input v-model="agentForm.company" :value="agentForm.company" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20" :offset="2">
                    <el-form-item label="账户类型：" prop="account_type">
                        <el-select v-model="agentForm.account_type" :value="agentForm.account_type" style="width:100%">
                            <el-option label="对私" :value="0" />
                            <el-option label="对公" :value="1" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20" :offset="2">
                    <el-form-item label="收款人账号：" prop="account_no">
                        <el-input v-model="agentForm.account_no" :value="agentForm.account_no" maxlength="19" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20" :offset="2">
                    <el-form-item label="收款人名称：" prop="account_name">
                        <el-input v-model="agentForm.account_name" :value="agentForm.account_name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20" :offset="2">
                    <el-form-item label="联系人：" prop="name">
                        <el-input v-model="agentForm.name" :value="agentForm.name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20" :offset="2">
                    <el-form-item label="联系电话：" prop="phone">
                        <el-input v-model="agentForm.phone" maxlength="11" :value="agentForm.phone" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20" :offset="2">
                    <el-form-item label="密码：" prop="password">
                        <el-input v-model="agentForm.password" :value="agentForm.password" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="submit">保存</el-button>
        </template>
    </el-dialog>
    <rate ref="rateRef"></rate>
    <device ref="deviceRef"></device>
</template>

<script setup >
import cTable from '@/components/CustomTable'
import rate from './configure'
import device from './device'
import { timeFormat } from '@/func/time'
import { ref, getCurrentInstance, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus, Search } from '@element-plus/icons-vue'
import { phone, bankNumber } from '@/func/validatorFunc'

const { proxy } = getCurrentInstance()
const tableRef = ref(null)
const rateRef = ref(null)
const agentFormRef = ref(null)
const deviceRef = ref(null)
const tableDataUrl = proxy.$api.agent.list
const isShowAddAgent = ref(false)
const isEdit = ref(false)

const searchForm = ref({
    company: ''
})

const init = ref({
    account_name: "",
    account_no: "",
    account_type: 0,
    company: "",
    name: "",
    phone: "",
    password: "",
})
const agentForm = ref({
    account_name: "",
    account_no: "",
    account_type: 0,
    company: "",
    name: "",
    phone: "",
    password: "",
})
const msg = ref('此项必填')
const rules = ref({
    account_name: [
        { required: true, message: msg }
    ],
    account_no: [
        { required: true, validator: bankNumber, trigger: 'change' }
    ],
    account_type: [
        { required: true, message: msg }
    ],
    company: [
        { required: true, message: msg }
    ],
    name: [
        { required: true, message: msg }
    ],
    phone: [
        { required: true, validator: phone, trigger: 'change' }
    ],
    password: [
        { required: true, message: msg }
    ],
})

const search = () => {
    nextTick(() => {
        proxy.$refs.tableRef.search(searchForm.value)
    })
}

const addAgent = () => {
    isShowAddAgent.value = true
}

const cancel = () => {
    isShowAddAgent.value = false
}

const submit = () => {
    proxy.$refs.agentFormRef.validate(validate => {
        if (validate) {
            if (isEdit.value) {
                proxy.$api.agent.editAgent(agentForm.value.id, agentForm.value).then(r => {
                    response(r)
                })
            } else {
                proxy.$api.agent.addAgent(agentForm.value).then(r => {
                    response(r)
                })
            }
        }
    })
}

const response = (data) => {
    if (data.status == 200) {
        isShowAddAgent.value = false
        nextTick(() => {
            tableRef.value.refresh()
        })
    } else {
        ElMessage({ message: data.error.message, type: "error" })
    }
}

const editAgent = (agent) => {
    isEdit.value = true
    agentForm.value = agent
    agentForm.value.password = '******'
    isShowAddAgent.value = true
}

const deleteAgent = (agent) => {
    ElMessageBox.confirm(
        '你确定要删除名称为 ' + agent.name + ' 的代理商?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    ).then(() => {
        proxy.$api.agent.delete(agent.id).then(r => {
            if (r.status == 200) {
                ElMessage({ message: '成功删除', type: 'success' })
                nextTick(() => {
                    tableRef.value.refresh()
                })
            } else {
                ElMessage({ message: '删除失败', type: 'error' })
            }

        })
    }).catch(() => {
        console.log('取消')
    })
}

const closeDialog = () => {
    agentForm.value = init.value
}

const configureRate = (agent) => {
    rateRef.value.open(agent)
}

const openDevice = (agent) => {
    nextTick(() => {
        proxy.$refs.deviceRef.open(agent)
    })
}
</script>

<style lang="less">
.ml15 {
    margin-left: 15px;
}

.link {
    cursor: pointer;
}
</style>